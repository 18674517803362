.body {
    background-color: #c4d6d4;
    height: 100vh;
    width: 100vw;
}

.Centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: #c4d6d4;
}

.Text-name {
    color: white;
    font-weight: 400;
    font-family: sans-serif;
    font-size: 1.93vw;
    margin-block-start: 1vw;
    margin-block-end: 0px;
    letter-spacing: 0.2vw;
}

.Text {
    color: white;
    font-weight: 450;
    font-family: sans-serif;
    font-size: 1vw;
    margin-block-start: 0.4vw;
    margin-block-end: 1.1vw;
    letter-spacing: 0.1vw;
}

.Text-container {
    width: 15vw;
    text-align: center;
}

.Social-container {
    width: 15vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

.Social-icon {
    height: 2.5vw;
}

.Avataar {
    width: 15vw;
}

@media screen and (max-width: 850px) {
    .Text-name {
        font-size: 10vw;
    }

    .Text {
        color: white;
        font-weight: 450;
        font-family: sans-serif;
        font-size: 5vw;
        margin-block-start: 1vw;
        margin-block-end: 3vw;
        letter-spacing: 0.1vw;
    }

    .Text-container {
        width: 75vw;
        text-align: center;
    }

    .Social-container {
        width: 75vw;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-evenly;
    }

    .Social-icon {
        height: 10vw;
    }

    .Avataar {
        width: 74vw;
    }
}
